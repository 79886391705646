import { ActionTypes } from "../constants/index";

export const pushNotificationsConnectAction = (token, userId) => ({
  type: ActionTypes.CONNECT_WEBSOCKET_NOTIFICATIONS,
  token,
  userId
});

export const pushNotificationsDisconnectAction = () => ({
  type: ActionTypes.DISCONNECT_WEBSOCKET_NOTIFICATIONS
});

export const getUnreadNotificationsCountAction = () => ({
  type: ActionTypes.GET_UNREAD_NOTIFICATIONS_COUNT
});
