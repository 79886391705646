import { takeEvery, call, put } from 'redux-saga/effects';
import { ActionTypes } from 'shared/constants';

import Api from '../api';

function* getSubdomainsSaga() {
  try {
    const { response, request } = yield call(Api.User.getSubdomains);

    if (request.status && request.status === 200) {
      yield put({
        type: ActionTypes.GET_SUBDOMAINS_SUCCESS,
        payload: response.content
      });
    }
  } catch (e) {
    console.log('error: ', e);
  }
}

export default function* watchSubdomainsSaga() {
  yield takeEvery(ActionTypes.GET_SUBDOMAINS, getSubdomainsSaga);
}
