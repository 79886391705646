import { takeEvery } from 'redux-saga/effects';
import { ActionTypes } from "../constants";
import utils from "../utils";
import Api from "../api";
import patternSaga from "./patternSaga";

const usersListsApiUrl = `${utils.config.apiUserUrl}/users/search`;

const sagaConfig = {
  requestFunction: Api.User.fetchUsersList,
  onFailure: {
    notification: true
  }
};

export default function* watchUsersLists() {
  yield takeEvery(ActionTypes.ADMIN_USERS_LIST_REQUEST, patternSaga, {
    ...sagaConfig,
    requestUrl: `${usersListsApiUrl}/admins`
  });
  yield takeEvery(ActionTypes.STRATEGIC_PARTNERS_LIST_REQUEST, patternSaga, {
    ...sagaConfig,
    requestUrl: `${usersListsApiUrl}/strategic-partners`
  });
  yield takeEvery(ActionTypes.ISSUERS_LIST_REQUEST, patternSaga, {
    ...sagaConfig,
    requestUrl: `${usersListsApiUrl}/issuers`
  });
  yield takeEvery(ActionTypes.INVESTORS_LIST_REQUEST, patternSaga, {
    ...sagaConfig,
    requestUrl: `${usersListsApiUrl}/investors`
  });
  yield takeEvery(ActionTypes.ADVISORS_LIST_REQUEST, patternSaga, {
    ...sagaConfig,
    requestUrl: `${usersListsApiUrl}/advisors`
  });
  yield takeEvery(ActionTypes.SP_INVESTORS_LIST_FETCH, patternSaga, {
    ...sagaConfig,
    requestUrl: `${usersListsApiUrl}/sp-investors`
  });
  yield takeEvery(ActionTypes.SP_ISSUERS_LIST_FETCH, patternSaga, {
    ...sagaConfig,
    requestUrl: `${usersListsApiUrl}/sp-issuers`
  });
}
