import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import countries from './countriesReducer';
import subdomains from './subdomainsReducer';
import spAdminDetails from './spPlatformConfigReducer';
import notifications from './notificationReducer';
import showSpinner from './spinnerReducer';
import user from './userDataReducer';
import projects from './projectsReducer';
import usersLists from './usersListsReducer';
import modals from './modalsReducer';
import payments from './paymentsReducer';
import dashboards from './dashboardReducer';
import sharedReducers from 'shared/reducers';

// Combines all reducers to a single reducer function
const rootReducer = combineReducers({
  user,
  showSpinner,
  countries,
  subdomains,
  notifications,
  projects,
  usersLists,
  modals,
  form: formReducer,
  router: routerReducer,
  payments,
  dashboards,
  spAdminDetails,
  ...sharedReducers
});

export default rootReducer;
