import config from "./config";
import menu from "../constants/menu";
import NetworkLayer from "./networkLayer";
import moment from "moment";

Date.prototype.format = function(format) {
  const o = {
    "M+": this.getMonth() + 1,
    "d+": this.getDate(),
    "h+": this.getHours(),
    "H+": this.getHours(),
    "m+": this.getMinutes(),
    "s+": this.getSeconds(),
    "q+": Math.floor((this.getMonth() + 3) / 3),
    S: this.getMilliseconds()
  };
  if (/(y+)/.test(format)) {
    format = format.replace(RegExp.$1, `${this.getFullYear()}`.substr(4 - RegExp.$1.length));
  }

  for (let k in o) {
    if (new RegExp(`(${k})`).test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length)
      );
    }
  }

  return format;
};

export default {
  config,
  menu,
  NetworkLayer
};

export function formatDate(date, format = 'DD.MM.YYYY') {
  if (!date) {
    return '';
  }
  const dateUTC = moment.utc(date);
  return moment(dateUTC).local().format(format);
}

export function isAfter(date, formattedDate) {
  return date ? moment(date).isAfter(formattedDate) : "";
}

export function labelFieldItem(fieldName) {
  return fieldName.split(/(?=[A-Z])/).map((key, i) => i > 0 ? key.toLowerCase() : key.charAt(0).toUpperCase() + key.substr(1)).join(" ");
}

export function counterCropper(number) {
  function splice(str, start, rem, subStr) {
    return str.slice(0, start) + subStr + str.slice(start + Math.abs(rem));
  }

  function formatString(str, dif, suffix) {
    str = str.slice(0, str.length - dif);
    return splice(str, str.length - 1, 0, ".").replace(".0", "") + suffix;
  }

  if (number) {
    const str = String(number);

    return (
      (number >= 1000000000 && formatString(str, 8, "b")) ||
      (number >= 1000000 && formatString(str, 5, "m")) ||
      (number >= 1000 && formatString(str, 2, "k")) ||
      str
    );
  } else {
    return "0";
  }
}

export function lowerCaseAllWordsExceptFirstLetters(status) {
  return status.charAt(0) + status.substr(1).toLowerCase();
}

export function getQueryStringValue(key) {
  return key
    ? decodeURIComponent(
        window.location.search.replace(
          new RegExp(
            "^(?:.*[&\\?]" +
              encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") +
              "(?:\\=([^&]*))?)?.*$",
            "i"
          ),
          "$1"
        )
      )
    : false;
}

// Usage example:
// [1,2,1,3,4,4,5].filter( onlyUnique ) => [1, 2, 3, 4, 5]
export function onlyUniqueArray(value, index, self) {
  return self.indexOf(value) === index;
}
