import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import Routes from './routes';
import App from 'shared/components/App';
import platforms from 'shared/constants/platforms';
import 'shared/styles/main-theme.css';
import './config/i18n';

const rootElement = document.getElementById('root');

render(
  <Provider store={store}>
    <App platform={platforms.ADMIN}>
      <Routes />
    </App>
  </Provider>,
  rootElement
);
