//      

import utils from "../utils";

class ActiveRecord {
  _networkLayer = "";
  _config = "";
  _logPromise = "";

  constructor() {
    this._networkLayer = utils.NetworkLayer;
    this._config = utils.config;
    this._logPromise = Promise.resolve();
  }

  get id() {
    return this._id;
  }

  get config() {
    return this._config;
  }

  get networkLayer() {
    return this._networkLayer;
  }

  set id(id) {
    this._id = id;
  }
}

export default ActiveRecord;
