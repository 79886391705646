import { ActionTypes } from 'shared/constants';
import initialState from './initialState';

export default (state = initialState.spAdminDetails, action) => {
  switch (action.type) {
    case ActionTypes.GET_SP_PLATFORM_CONFIG_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
