const protocol = 'https:';
export const version = 'v2';
export const apiUrl = `${protocol}//${window.API_URL}`;

export default {
  apiUrl,
  version,
  protocol,
  apiConfigUrl: `${apiUrl}/user/api/${version}/configs`,
  apiUserUrl: `${apiUrl}/user/api/${version}`,
  apiUrlAuth: `${protocol}//${window.API_URL_AUTH}`,
  apiProjectUrl: `${apiUrl}/project/api/${version}`,
  apiFileCategoriesUrl: `${apiUrl}/file/api/${version}/categories`,
  apiFilesUrl: `${apiUrl}/file/api/${version}/storage`,
  apiNotificationsUrl: `${apiUrl}/user/api/${version}/notifications`,
  apiPaymentsUrl: `${apiUrl}/user/api/${version}/mango-pay`,
  apiProjectFilesUrl: `${apiUrl}/file/api/${version}/storage/projects`,
  pushNotificationUrl: `wss://${window.API_URL}/user-ws/api/${version}/ws`,
  exelUrl: `${apiUrl}/user/api/${version}/user/me/export`,
  name: '',
  prefix: '',
  footerText: '',
  logoSrc: 'assets/lamp.svg',
  logoText: '',
  needLogin: false
};

export const LoginConfig = {
  CLIENT_ID: 'web-console',
  CLIENT_SECRET: window.CLIENT_SECRET,
  HEADERS: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  PARAMS: {
    auth: {
      response_type: 'code',
      client_id: 'web-console',
      redirect_uri: `${window.location.protocol}//${window.location.host}/callback`,
      sub_domain: 'admin'
    },
    token: {
      grant_type: 'authorization_code',
      state: 234,
      redirect_uri: `${window.location.protocol}//${window.location.host}/callback`
    },
    refreshToken: {
      grant_type: 'refresh_token',
      state: 234
    }
  }
};
