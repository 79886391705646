import { delay } from 'redux-saga';
import {
  takeEvery, call, put, select
} from 'redux-saga/effects';
import { ActionTypes } from '../constants/index';
import network from '../utils/networkLayer';
import utils from '../utils';
import { LoginConfig } from '../utils/config';
import setAuthorizationToken from '../utils/setAuthorizationToken';
import { history } from 'shared/routes/urlLocations';
import notification from '../utils/notifications';

const API_URL = utils.config.apiUrlAuth;

function* refreshToken() {
  // Getting expires in and tokens data from session storage
  const expiresIn = sessionStorage.getItem('expires_in');
  const token = sessionStorage.getItem('refresh_token');
  const accessToken = sessionStorage.getItem('access_token');

  let sub_domain = {
    sub_domain: 'admin'
  };

  // Checking is user logged and return null if not logged
  if (expiresIn && token && accessToken) {
    const delayNextRefresh = (expiresIn - 10) * 1000;
    const lastTimeRefreshToken = sessionStorage.getItem('lastTimeRefreshToken');

    // Checking is user was just logged in
    if (!lastTimeRefreshToken) {
      yield sessionStorage.setItem('lastTimeRefreshToken', new Date().getTime());
      yield delay(delayNextRefresh);
      return yield put({
        type: ActionTypes.REFRESH_TOKEN_REQUEST
      });
    }
    const timeDifference = new Date().getTime() - lastTimeRefreshToken;
    const newDelayNextRefresh = delayNextRefresh - timeDifference;

    // If user refresh the page
    if (newDelayNextRefresh > 0) {
      yield delay(newDelayNextRefresh);
      return yield put({
        type: ActionTypes.REFRESH_TOKEN_REQUEST
      });
    }
    const authorizationHeader = `Basic ${btoa(
      `${LoginConfig.CLIENT_ID}:${LoginConfig.CLIENT_SECRET}`
    )}`;
    const config = {
      headers: {
        ...LoginConfig.HEADERS,
        Authorization: authorizationHeader
      },
      params: {
        ...LoginConfig.PARAMS.refreshToken,
        ...sub_domain,
        refresh_token: token
      }
    };

    const { response, request, status } = yield call(
      network.postJson,
      `${API_URL}/oauth/token`,
      config
    );

    if (request.status && request.status === 200) {
      yield setAuthorizationToken(response);
      yield sessionStorage.setItem('lastTimeRefreshToken', new Date().getTime());
      yield delay(sessionStorage.getItem('expires_in'));
      yield put({
        type: ActionTypes.REFRESH_TOKEN_REQUEST
      });
    }
  } else {
    return null;
  }
}

export default function* watchRefreshToken() {
  yield takeEvery(ActionTypes.REFRESH_TOKEN_REQUEST, refreshToken);
}
