import { ActionTypes } from 'shared/constants';
import { chartTypes } from 'shared/constants/charts';

import initialState from './initialState';

export default (state = initialState.dashboard, action) => {
  switch (action.type) {
    case ActionTypes.GET_DASHBOARD_STRUCTURE_SUCCESS:
      return {
        ...state,
        structure: action.payload.content
      };

    case ActionTypes.CHART_REQUEST_STARTED:
    case ActionTypes.CHART_REQUEST_USERS_STARTED: {
      const { chartType, view } = action.payload;

      const chartKey = `${chartType}-${view}`;

      return {
        ...state,
        charts: {
          ...state.charts,
          [chartKey]: {
            ...state.charts[chartKey],
            content: [],
            loading: true,
            error: false
          }
        }
      };
    }

    case ActionTypes.CHART_REQUEST_SUCCESS:
    case ActionTypes.CHART_REQUEST_USERS_SUCCESS: {
      const { chartType, view, content } = action.payload;
      const chartKey = `${chartType}-${view}`;

      return {
        ...state,
        charts: {
          ...state.charts,
          [chartKey]: {
            ...state.charts[chartKey],
            content,
            loading: false,
            error: false
          }
        }
      };
    }

    case ActionTypes.CHART_OPEN_DETAILED_VIEW: {
      const { chartType } = action.payload;
      const smallChartKey = `${chartType}-small`;
      const fullChartKey = `${chartType}-full`;

      return {
        ...state,
        charts: {
          ...state.charts,
          [fullChartKey]: {
            ...state.charts[fullChartKey],
            params: {
              ...state.charts[smallChartKey].params
            }
          }
        }
      };
    }

    case ActionTypes.CHART_PARAMS_SET: {
      const { chartType, view, params } = action.payload;
      const chartKey = `${chartType}-${view}`;

      return {
        ...state,
        charts: {
          ...state.charts,
          [chartKey]: {
            ...state.charts[chartKey],
            params: {
              ...state.charts[chartKey].params,
              ...params
            }
          }
        }
      };
    }

    case ActionTypes.CHART_REQUEST_FAILURE:
    case ActionTypes.CHART_REQUEST_USERS_FAILURE: {
      const { chartType, view } = action.payload;
      const chartKey = `${chartType}-${view}`;

      return {
        ...state,
        charts: {
          ...state.charts,
          [chartKey]: {
            ...state.charts[chartKey],
            content: [],
            loading: false,
            error: true
          }
        }
      };
    }

    case ActionTypes.GET_ALL_TAGS_STARTED:
      return {
        ...state,
        tags: {
          ...state.tags,
          loading: true,
          error: false
        }
      };

    case ActionTypes.GET_SUBDOMAINS_SUCCESS: {
      const targetViews = [`${chartTypes.AMOUNT_OF_SP_USERS}-small`];
      const firstSp = action.payload[0];

      return {
        ...state,
        charts: {
          ...state.charts,
          ...targetViews.reduce(
            (acc, targetView) => ({
              ...acc,
              [targetView]: {
                ...state.charts[targetView],
                params: {
                  ...state.charts[targetView].params,
                  sp: firstSp
                }
              }
            }),
            {}
          )
        }
      };
    }

    case ActionTypes.GET_ALL_TAGS_SUCCESS: {
      const tagsData = action.payload.content || [];
      const regionsTags = tagsData.find(({ type }) => type === 'REGION').tags;
      const productCategoriesTags = tagsData.find(({ type }) => type === 'PRODUCT_CATEGORY').tags;

      const targetViews = [
        `${chartTypes.AMOUNT_OF_PUBLISHED_PROJECTS}-small`,
        `${chartTypes.AMOUNT_OF_SUCCESSFUL_PROJECTS}-small`,
        `${chartTypes.AMOUNT_OF_UNSUCCESSFUL_PROJECTS}-small`
      ];

      const getDefaultParams = (view, regions, productCategories) => ({
        params: {
          ...state.charts[view].params,
          regions: regions.map(region => region.id),
          productCategories: productCategories.map(productCategory => productCategory.id)
        }
      });

      return {
        ...state,
        tags: {
          ...state.tags,
          data: {
            PRODUCT_CATEGORY: productCategoriesTags.map(tag => ({
              title: tag.name,
              value: tag.id
            })),
            REGION: regionsTags.map(tag => ({
              title: tag.name,
              value: tag.id
            }))
          },
          loading: false,
          error: false
        },
        charts: {
          ...state.charts,

          ...targetViews.reduce(
            (acc, targetView) => ({
              ...acc,
              [targetView]: {
                ...state.charts[targetView],
                ...getDefaultParams(targetView, regionsTags, productCategoriesTags)
              }
            }),
            {}
          )
        }
      };
    }

    default:
      return state;
  }
};
