import { takeEvery, call, put } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import NetworkLayer from "../utils/networkLayer";
import utils from "../utils";
import notification from "../utils/notifications";
import { history } from 'shared/routes/urlLocations';

function* getCountriesSaga() {
  try {
    const { response, request, status } = yield call(
      NetworkLayer.getJson,
      `${utils.config.apiUserUrl}/public/countries`
    );
    if (request.status && request.status === 200) {
      yield put({
        type: ActionTypes.GET_COUNTRIES_SUCCESS,
        payload: response
      });
    }
  } catch (e) {
    console.log("error: ", e);
  }
}

export default function* watchCountriesSaga() {
  yield takeEvery(ActionTypes.GET_COUNTRIES_REQUEST, getCountriesSaga);
}
