import networkLayer from './networkLayer';

export default data => {
  if (data && Object.keys(data).length !== 0) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        sessionStorage.setItem(key, data[key]);
      }
    }

    networkLayer.authToken = `Bearer ${data.access_token}`;
  } else {
    if (Object.keys(sessionStorage).length !== 0) {
      for (const key in sessionStorage) {
        if (window.sessionStorage.hasOwnProperty(key)) {
          sessionStorage.removeItem(key);
        }
      }
    }

    networkLayer.authToken = '';
  }
};
