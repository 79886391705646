import { takeEvery, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import utils from '../utils';
import Api from '../api';
import patternSaga from './patternSaga';
import * as pathname from 'shared/routes/urlLocations';
import { ActionTypes as SharedActionTypes } from 'shared/constants';

const projectsOngoingSagaConfig = {
  requestFunction: Api.Projects.getProjects,
  requestUrl: `${utils.config.apiProjectUrl}/projects/ongoing`,
  onFailure: {
    redirectTo: pathname.homePage,
    notification: true
  }
};

const projectsFinishedSagaConfig = {
  requestFunction: Api.Projects.getProjects,
  requestUrl: `${utils.config.apiProjectUrl}/projects/finished`,
  onFailure: {
    redirectTo: pathname.homePage,
    notification: true
  }
};

const projectsRejectedSagaConfig = {
  requestFunction: Api.Projects.getProjects,
  requestUrl: `${utils.config.apiProjectUrl}/projects/rejected`,
  onFailure: {
    redirectTo: pathname.homePage,
    notification: true
  }
};

const projectsPendingSagaConfig = {
  requestFunction: Api.Projects.getProjects,
  requestUrl: `${utils.config.apiProjectUrl}/projects/pending`,
  onFailure: {
    redirectTo: pathname.homePage,
    notification: true
  }
};

const projectDetailsSagaConfig = {
  spinner: true,
  requestFunction: Api.Projects.getProjectDetails,
  requestUrl: `${utils.config.apiProjectUrl}/projects`,
  onFailure: {
    redirectTo: pathname.homePage,
    notification: false
  }
};

const projectApproveSagaConfig = {
  spinner: true,
  requestFunction: Api.Projects.approveProject,
  requestUrl: `${utils.config.apiProjectUrl}/projects`,
  onSuccess: {
    redirectTo: [pathname.projects, { activeTab: '3' }],
    notification: {
      staticNotification: true,
      data: {
        code: '4'
      }
    }
  },
  onFailure: {
    notification: true
  }
};

const projectRejectSagaConfig = {
  spinner: true,
  requestFunction: Api.Projects.rejectProject,
  requestUrl: `${utils.config.apiProjectUrl}/projects`,
  onSuccess: {
    redirectTo: [pathname.projects, { activeTab: '3' }],
    notification: {
      staticNotification: true,
      data: {
        code: '5'
      }
    }
  },
  onFailure: {
    notification: true
  }
};

const projectRejectionsHistorySagaConfig = {
  requestFunction: Api.Projects.getProjectRejectionsHistory,
  requestUrl: `${utils.config.apiProjectUrl}/projects`
};

const getFileCategoriesConfig = {
  requestFunction: Api.Projects.fileCategories,
  requestUrl: `${utils.config.apiFileCategoriesUrl}`,
  onFailure: {
    notification: true
  }
};

export default function* watchProjectsSaga() {
  yield takeEvery(ActionTypes.ONGOING_PROJECTS_GET_REQUEST, patternSaga, projectsOngoingSagaConfig);
  yield takeEvery(ActionTypes.FINISHED_PROJECTS_GET_REQUEST, patternSaga, projectsFinishedSagaConfig);
  yield takeEvery(ActionTypes.PENDING_PROJECTS_GET_REQUEST, patternSaga, projectsPendingSagaConfig);
  yield takeEvery(ActionTypes.REJECTED_PROJECTS_GET_REQUEST, patternSaga, projectsRejectedSagaConfig);
  yield takeEvery(ActionTypes.PROJECT_DETAILS_GET_REQUEST, patternSaga, projectDetailsSagaConfig);
  yield takeEvery(ActionTypes.PROJECT_APPROVE_REQUEST, patternSaga, projectApproveSagaConfig);
  yield takeEvery(ActionTypes.PROJECT_REJECT_REQUEST, patternSaga, projectRejectSagaConfig);
  yield takeEvery(
    ActionTypes.PROJECT_REJECTIONS_HISTORY_GET_REQUEST,
    patternSaga,
    projectRejectionsHistorySagaConfig
  );
  yield takeLatest(SharedActionTypes.GET_FILE_CATEGORIES, patternSaga, getFileCategoriesConfig);
}
