import { ActionTypes } from "../constants";
import initialState from "./initialState";

export default (state = initialState.modals, { type, payload = {} }) => {
  switch (type) {
    case ActionTypes.MODAL_REJECT_PROJECT_TOGGLE:
      return {
        ...state,
        rejectProject: !state.rejectProject
      };
    case ActionTypes.MODAL_TOGGLE:
      return {
        ...state,
        spModalToggle: {
          isOpen: !state.spModalToggle.isOpen,
          selectedItemId: payload
        }
      };
    default:
      return state;
  }
};
