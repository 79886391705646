import { takeEvery, call, put } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import utils, { getQueryStringValue } from '../utils';
import Api from '../api';
import notification from '../utils/notifications';
import patternSaga from './patternSaga';
import { ActionTypes as sharedActionTypes } from 'shared/constants';

function* initialUserInformation() {
  try {
    yield put({
      type: ActionTypes.USER_INFORMATION_REQUEST_STARTED,
      payload: {
        requestFrom: 'initialUserInformation'
      }
    });

    const { response, request, status } = yield call(
      Api.User.initiateUserInformation,
      `${utils.config.apiUserUrl}/user/me`
    );
    if (request.status && request.status === 200) {
      yield put({
        type: ActionTypes.USER_INFORMATION_REQUEST_SUCCESS,
        message: response
      });
      window.sessionStorage.setItem('email', response.email);
    } else {
      yield put({
        type: ActionTypes.USER_INFORMATION_REQUEST_FAILURE,
        message: response
      });
    }
  } catch ({ message }) {
    yield put({
      type: ActionTypes.USER_INFORMATION_REQUEST_FAILURE,
      message
    });
  } finally {
    yield put({
      type: ActionTypes.USER_INFORMATION_REQUEST_FINISHED,
      payload: {
        requestFrom: 'initialUserInformation'
      }
    });
  }
}

function* KYCUserStatus({ id, projectionName }) {
  yield put({
    type: ActionTypes.SHOW_SPINNER
  });
  try {
    yield put({
      type: ActionTypes.KYC_USER_STATUS_STARTED,
      payload: {
        requestFrom: 'KYCUserStatus'
      }
    });
    const projection = getQueryStringValue('projectionName') || projectionName;
    const { response, request, status } = yield call(
      Api.User.KYCUserStatus,
      `${utils.config.apiUserUrl}/user/${id}?projectionName=${projection}`
    );

    if (request.status && (request.status === 204 || request.status === 200)) {
      delete response.links;
      yield put({
        type: ActionTypes.KYC_USER_STATUS_SUCCESS,
        payload: response
      });
    } else {
      yield call(notification, {
        type: 'error',
        data: response,
        status
      });
    }
  } catch ({ message }) {
    yield put({
      type: ActionTypes.KYC_USER_STATUS_FAILURE,
      message
    });
  } finally {
    yield put({
      type: ActionTypes.KYC_USER_STATUS_FINISHED,
      payload: {
        requestFrom: 'KYCUserStatus'
      }
    });
    yield put({
      type: ActionTypes.HIDE_SPINNER
    });
  }
}

function* setUserKYCStatusSagaConfig({ requestConfig, onSuccess }) {
  yield put({ type: ActionTypes.SHOW_SPINNER });
  try {
    yield put({
      type: ActionTypes.SET_USER_KYC_STATUS_STARTED
    });

    let id = requestConfig.id.split('?')[0];
    let config = { ...requestConfig, id };
    const { response, request, status } = yield call(Api.User.setUserKYCStatus, config);
    if (
      request.status
      && (request.status === 200 || request.status === 201 || request.status === 204)
    ) {
      yield put({
        type: ActionTypes.SET_USER_KYC_STATUS_SUCCESS,
        payload: response
      });
      yield call(notification, {
        type: 'success',
        ...onSuccess.notification,
        status
      });
      yield call(onSuccess.redirectTo);
    } else {
      yield call(notification, { type: 'error', data: response, status });
    }
  } catch ({ message }) {
    yield put({
      type: ActionTypes.SET_USER_KYC_STATUS_FAILURE,
      message
    });
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

const setAdminUserStatusSagaConfig = {
  requestFunction: Api.User.adminUserStatusHandle,
  requestUrl: `${utils.config.apiUserUrl}/user`
};

const contactUsRequestConfig = {
  requestFunction: Api.User.contactUsRequest,
  requestUrl: utils.config.apiUserUrl,
  spinner: true,
  onSuccess: {
    notification: {
      staticNotification: true,
      data: {
        code: 12
      }
    }
  },
  onFailure: {
    notification: true
  }
};

function* activateSpSubscriptionSaga({ payload: { subDomain } }) {
  try {
    const { response, request, status } = yield call(
      Api.User.activateSpSubscription,
      `${utils.config.apiUserUrl}/subscriptions/activate`,
      {
        data: {
          subDomain
        }
      }
    );

    if (
      request.status
      && (request.status === 200 || request.status === 201 || request.status === 204)
    ) {
      yield call(notification, {
        type: 'success',
        data: {
          description: 'SP subscription was activated.'
        }
      });
    } else {
      yield call(notification, { type: 'error', data: response, status });
    }
  } catch (e) {
    yield call(notification, {
      type: 'error',
      data: {
        description: 'SP subscription was not activated. Please try again in few minutes.'
      }
    });
  }
}

export default function* watchUserData() {
  yield takeEvery(ActionTypes.USER_INFORMATION_REQUEST, initialUserInformation);
  yield takeEvery(ActionTypes.KYC_USER_STATUS, KYCUserStatus);
  yield takeEvery(ActionTypes.ADMIN_USER_SET_STATUS, patternSaga, setAdminUserStatusSagaConfig);
  yield takeEvery(ActionTypes.SET_USER_KYC_STATUS, setUserKYCStatusSagaConfig);
  yield takeEvery(sharedActionTypes.CONTACT_US_REQUEST, patternSaga, contactUsRequestConfig);
  yield takeEvery(ActionTypes.ACTIVATE_SP_SUBSCRIPTION, activateSpSubscriptionSaga);
}
