import { call, fork } from 'redux-saga/effects';
import watchLogin from './loginSaga';
import watchLogOutSaga from './logOutSaga';
import watchForgotPassword from './forgotPasswordSaga';
import { watchCheckUsedToken, watchRestorePassword } from './restorePasswordSaga';
import watchCountriesSaga from './countriesSaga';
import watchSubdomainsSaga from './subdomainsSaga';
import watchSpPlatformConfigSaga from './spPlatformConfigSaga';
import watchRefreshToken from './refreshTokenSaga';
import watchUserData from './userDataSaga';
import watchUsersLists from './usersListsSaga';
import watchProjectsSaga from './projectsSaga';
import { watchCheckConfirmToken, watchConfirmationEmail } from './confirmEmailSaga';
import watchChangePasswordSaga from './changePasswordSaga';
import watchNotifications from './notificationsSaga';
import watchUserRegistrationSaga from './userRegistrationSaga';
import { getWalletStatus } from './paymentsSaga';
import watchUserPlatformSaga from './userPlatformSaga';
import watchPushNotificationsSaga from './pushNotificationsSaga';
import dashboardSaga from './dashboardSaga';
import watchexportUsersToExelSaga from './exportUsersToExelSaga';

function forkAutoRestarting(fn, ...args) {
  return fork(function*() {
    while (true) {
      try {
        yield call(fn, ...args);
      } catch (e) {
        console.log('Unhandled error', e);
      }
    }
  });
}

export default function* rootSaga() {
  yield [
    forkAutoRestarting(watchLogin),
    forkAutoRestarting(watchLogOutSaga),
    forkAutoRestarting(watchPushNotificationsSaga),
    forkAutoRestarting(watchForgotPassword),
    forkAutoRestarting(watchCheckUsedToken),
    forkAutoRestarting(watchRestorePassword),
    forkAutoRestarting(watchCountriesSaga),
    forkAutoRestarting(watchSpPlatformConfigSaga),
    forkAutoRestarting(watchSubdomainsSaga),
    forkAutoRestarting(watchRefreshToken),
    forkAutoRestarting(watchUserData),
    forkAutoRestarting(watchUsersLists),
    forkAutoRestarting(watchProjectsSaga),
    forkAutoRestarting(watchConfirmationEmail),
    forkAutoRestarting(watchCheckConfirmToken),
    forkAutoRestarting(watchChangePasswordSaga),
    forkAutoRestarting(watchNotifications),
    forkAutoRestarting(watchUserRegistrationSaga),
    forkAutoRestarting(getWalletStatus),
    forkAutoRestarting(watchUserPlatformSaga),
    forkAutoRestarting(dashboardSaga),
    forkAutoRestarting(watchexportUsersToExelSaga)
  ];
}
