import { ActionTypes } from 'shared/constants';
import initialState from './initialState';

export default (state = initialState.subdomains, action) => {
  switch (action.type) {
    case ActionTypes.GET_SUBDOMAINS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
