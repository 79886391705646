import { takeEvery, call, put } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import { history } from 'shared/routes/urlLocations';
import utils from "../utils";
import Api from "../api";
import notification from "../utils/notifications";

function* getNotifications({ group = "PROJECT", unread = false, page: { current } }) {
  yield put({ type: ActionTypes.UPLOAD_NOTIFICATIONS_STARTED });
  yield put({ type: ActionTypes.SHOW_SPINNER });

  try {
    const { response, request, status } = yield call(
      Api.Notifications.getNotifications,
      `${utils.config.apiUserUrl}/notifications`,
      {
        group,
        unread,
        size: 10,
        page: current - 1 || 0
      }
    );

    if (request.status && request.status === 200) {
      yield put({
        type: ActionTypes.UPLOAD_NOTIFICATIONS_SUCCESS,
        payload: response
      });
    } else {
      yield call(notification, {
        type: "error",
        data: response
      });
    }
  } catch ({ message }) {
    console.error(message);
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
    yield put({ type: ActionTypes.UPLOAD_NOTIFICATIONS_FINISHED });
  }
}

function* markNotificationAsRead({ id, read }) {
  if (!read) {
    yield put({ type: ActionTypes.MARK_NOTIFICATION_AS_READ_STARTED });
    try {
      const { response, request, status } = yield call(
        Api.Notifications.markNotificationAsRead,
        `${utils.config.apiUserUrl}/notifications/${id}`
      );

      if (request.status && request.status === 204) {
        yield put({
          type: ActionTypes.MARK_NOTIFICATION_AS_READ_SUCCESS,
          payload: { id }
        });
        yield put({ type: ActionTypes.GET_UNREAD_NOTIFICATIONS_COUNT });
      } else {
        yield call(notification, {
          type: "error",
          data: response
        });
      }
    } catch ({ message }) {
      console.error(message);
    } finally {
      yield put({ type: ActionTypes.MARK_NOTIFICATION_AS_READ_FINISHED });
    }
  }
}

function* deleteNotification({ id, unread, page, group }) {
  yield put({ type: ActionTypes.DELETE_NOTIFICATION_STARTED });
  yield put({ type: ActionTypes.SHOW_SPINNER });
  try {
    const { response, request, status } = yield call(
      Api.Notifications.deleteNotification,
      `${utils.config.apiUserUrl}/notifications/${id}`
    );

    if (request.status && request.status === 204) {
      yield put({
        type: ActionTypes.DELETE_NOTIFICATION_SUCCESS,
        payload: { id }
      });
      yield put({ type: ActionTypes.GET_UNREAD_NOTIFICATIONS_COUNT });
    } else {
      yield call(notification, {
        type: "error",
        data: response
      });
    }
  } catch ({ message }) {
    console.error(message);
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
    yield put({ type: ActionTypes.DELETE_NOTIFICATION_FINISHED });
    yield put({ type: ActionTypes.UPLOAD_NOTIFICATIONS, unread, page, group });
  }
}

export default function* watchNotifications() {
  yield takeEvery(ActionTypes.UPLOAD_NOTIFICATIONS, getNotifications);
  yield takeEvery(ActionTypes.MARK_NOTIFICATION_AS_READ, markNotificationAsRead);
  yield takeEvery(ActionTypes.DELETE_NOTIFICATION, deleteNotification);
}
