import { takeEvery } from 'redux-saga/effects';
import { call, put } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import Api from "../api";
import utils from "../utils";
import notification from "../utils/notifications";
import { history } from 'shared/routes/urlLocations';

function* forgotPassword(action) {
  try {
    yield put({ type: ActionTypes.FORGOT_PASSWORD_REQUEST_STARTED });
    yield put({ type: ActionTypes.SHOW_SPINNER });

    const { email } = action;

    const { response, request, status } = yield call(
      Api.Auth.forgotPassword,
      `${utils.config.apiUserUrl}/public/password/forgot`,
      email
    );

    if (request.status && request.status === 200) {
      yield call(notification, {
        type: "success",
        data: response,
        status
      });
      yield history.push("/");
    } else if (request.status && request.status >= 500) {
      yield call(notification, {
        type: "error",
        data: "An error occurred. Please, try again"
      });
    } else {
      yield call(notification, {
        type: "error",
        data: response,
        status
      });
    }
  } catch (e) {
    yield call(notification, {
      type: "error",
      data: e.message
    });
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
    yield put({ type: ActionTypes.FORGOT_PASSWORD_REQUEST_FINISHED });
  }
}

export default function* watchForgotPassword() {
  yield takeEvery(ActionTypes.FORGOT_PASSWORD_REQUEST, forgotPassword);
}
