import {
  takeEvery, call, put, all
} from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import utils from '../utils';
import Api from '../api';
import { history } from 'shared/routes/urlLocations';
import notification from '../utils/notifications';
import { userHandlingAdminUsers, strategicPartnersSubscriptions } from 'shared/routes/urlLocations';
import _ from 'lodash';

function* adminUserCreate({ values }) {
  try {
    yield put({
      type: ActionTypes.ADMIN_USER_CREATE_STARTED,
      payload: {
        requestFrom: 'adminUserCreate'
      }
    });

    const { response, request, status } = yield call(
      Api.User.adminUserCreate,
      `${utils.config.apiUserUrl}/register`,
      { ...values, userType: 'SUPER_ADMIN', type: 'USER' }
    );

    if (request.status && (request.status === 200 || request.status === 204)) {
      yield put({
        type: ActionTypes.ADMIN_USER_CREATE_SUCCESS,
        message: response
      });
      const description = `Confirmation e-mail will be sent to ${values.email}`;
      yield call(notification, {
        type: 'success',
        data: { description }
      });
      yield history.push(userHandlingAdminUsers);
    } else {
      yield call(notification, {
        type: 'error',
        data: response,
        status
      });
    }
  } catch (error) {
    console.log('error: ', error);
    yield put({
      type: ActionTypes.ADMIN_USER_CREATE_FAILURE,
      message: error.message
    });
  } finally {
    yield put({
      type: ActionTypes.ADMIN_USER_CREATE_FINISHED,
      payload: {
        requestFrom: 'adminUserCreate'
      }
    });
  }
}

const FILES_NAMES = {
  BACKGROUND_PICTURE: 'BACKGROUND_PICTURE',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  TERMS_OF_SERVICE: 'TERMS_OF_SERVICE',
  LOGO: 'LOGO'
};

const fieldFileTypeMap = {
  backgroundPicture: FILES_NAMES.BACKGROUND_PICTURE,
  privacyPolicyDocument: FILES_NAMES.PRIVACY_POLICY,
  termsOfServiceDocument: FILES_NAMES.TERMS_OF_SERVICE,
  logo: FILES_NAMES.LOGO
};

function* strategicPartnerCreate({ values, url, isApplication }) {
  yield put({ type: ActionTypes.SHOW_SPINNER });

  const uploadTasks = Object.entries(values.files).reduce((acc, [field, file]) => {
    if (file) {
      acc.push(call(Api.Registration.uploadSPPlatformConfigFile, fieldFileTypeMap[field], file));
    }

    return acc;
  }, []);

  try {
    const results = yield all(uploadTasks);

    const isFilesUploadSuccess = _.every(
      results,
      ({ request }) => request.status && (request.status === 200 || request.status === 204)
    );

    if (isFilesUploadSuccess) {
      const filesData = results.reduce((acc, { response }) => {
        const fieldName = [_.invert(fieldFileTypeMap)[response.type]];
        return {
          ...acc,
          [fieldName]: response
        };
      }, {});

      values.files = undefined;

      const strategicPartnerModel = {
        ...values,
        ...filesData
      };

      const { response, request, status } = yield call(
        Api.User.strategicPartnerCreate,
        `${utils.config.apiUserUrl}/register`,
        {
          ...strategicPartnerModel,
          userType: 'ADMIN_STRATEGIC_PARTNER',
          type: 'COMPANY',
          status: isApplication ? 'REGISTERED' : undefined
        }
      );

      if (request.status && (request.status === 200 || request.status === 204)) {
        yield put({
          type: ActionTypes.STRATEGIC_PARTNER_SUCCESS,
          message: response
        });
        const description = `Confirmation e-mail will be sent to ${values.email}`;
        yield call(notification, {
          type: 'success',
          data: { description }
        });

        yield history.push(
          `${strategicPartnersSubscriptions}/${
            response.id
          }/${url}?projectionName=companyRegistration`
        );
      } else {
        yield call(notification, {
          type: 'error',
          data: response,
          status
        });
      }
    } else {
      const errorTasks = [];

      results.forEach(({ request, response }) => {
        if (!(request.status && (request.status === 200 || request.status === 204))) {
          errorTasks.push(
            call(notification, {
              type: 'error',
              data: response
            })
          );
        }
      });

      yield all(errorTasks);
    }
  } catch (e) {
    console.log(e);
  }

  yield put({ type: ActionTypes.HIDE_SPINNER });
}

export default function* watchUserRegistrationSaga() {
  yield takeEvery(ActionTypes.ADMIN_USER_CREATE, adminUserCreate);
  yield takeEvery(ActionTypes.STRATEGIC_PARTNER, strategicPartnerCreate);
}
