import { takeEvery, call, put } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import utils from '../utils';
import Api from '../api';
import notification from '../utils/notifications';
import { history } from 'shared/routes/urlLocations';
import * as pathname from 'shared/routes/urlLocations';

const apiUrl = `${utils.config.apiUserUrl}/user/me/password`;

function* changePassword({ values }) {
  const { currentPassword, password, passwordConfirmation } = values;
  try {
    yield put({ type: ActionTypes.CHANGE_PASSWORD_STARTED });

    const { response, request, status } = yield call(Api.User.changePassword, apiUrl, {
      currentPassword,
      password,
      passwordConfirmation
    });
    if (request.status && (request.status === 200 || request.status === 204)) {
      yield put({
        type: ActionTypes.CHANGE_PASSWORD_SUCCESS,
        payload: response
      });
      yield history.push(pathname.projects);
      const description = {
        code: 0,
        description: 'Your password has been changed!'
      };
      yield call(notification, { type: 'success', data: { ...description } });
    } else if (request.status && request.status >= 500) {
      yield call(notification, {
        type: 'error',
        data: 'An error occurred. Please, try again'
      });
    } else {
      yield call(notification, { type: 'error', data: response });
    }
  } catch ({ message }) {
    yield put({
      type: ActionTypes.CHANGE_PASSWORD_FAILURE,
      message
    });
  } finally {
    yield put({ type: ActionTypes.CHANGE_PASSWORD_FINISHED });
  }
}

export default function* watchChangePassword() {
  yield takeEvery(ActionTypes.CHANGE_PASSWORD, changePassword);
}
