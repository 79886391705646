import React from 'react';
import { Router } from 'react-router';
import { Link } from 'react-router-dom';
import { Client } from '@stomp/stompjs/esm6';
import notification from 'shared/utils/notification';
import hash from 'object-hash';
import { ActionTypes } from '../constants';
import utils, { formatDate } from '../utils';
import { notifications as notificationsUrl, projects, history } from 'shared/routes/urlLocations';
import { getNotification, notificationGroup } from 'shared/constants/notifications';
import { getUnreadNotificationsCountAction } from '../actions/pushNotificationsActions';
import {
  getPendingProjects,
  getFinishedProjects,
  getOngoingProjects,
  getRejectedProjects
} from '../actions/projectsActions';
import { signOut } from '../actions/authActions';

let stompClient = null;
const reconnectDelay = 5000;
const heartbeatIncoming = 5000;
const heartbeatOutgoing = 5000;

function pushNotificationsConnect(store) {
  let accessToken = sessionStorage.getItem('access_token');
  let userId = sessionStorage.getItem('userId');
  let tokenType = sessionStorage.getItem('token_type');
  const connectHeaders = { Authorization: `${tokenType} ${accessToken}` };

  store.dispatch(getUnreadNotificationsCountAction());

  stompClient = new Client({
    brokerURL: utils.config.pushNotificationUrl,
    connectHeaders,
    reconnectDelay,
    heartbeatIncoming,
    heartbeatOutgoing
  });

  stompClient.onConnect = frame => onConnect(store, userId, frame);
  stompClient.onStompError = function() {
    store.dispatch(signOut());
  };

  stompClient.onDisconnect = function(onDisconnect) {
    console.log('onDisconnect', onDisconnect);
    store.dispatch(signOut());
    stompClient && stompClient.deactivate();
  };

  stompClient.onStompError = function(onStompError) {
    console.log('onStompError :', onStompError);
    store.dispatch(signOut());
    stompClient && stompClient.deactivate();
  };

  stompClient.onWebSocketClose = function(onWebSocketClose) {
    console.log('onWebSocketClose', onWebSocketClose);
    store.dispatch(signOut());
    stompClient && stompClient.deactivate();
  };

  stompClient.onWebSocketError = function(onWebSocketError) {
    console.log('onWebSocketError', onWebSocketError);
    store.dispatch(signOut());
    stompClient && stompClient.deactivate();
  };

  //stompClient.activate();
}

function pushNotificationsDisconnect() {
  stompClient && stompClient.deactivate();
}

function onConnect(store, userId, frame) {
  const {
    user: {
      info: { userType }
    }
  } = store.getState();
  let userNotify = `/topic/notify.${userId}`;
  stompClient.subscribe(userNotify, req => {
    const { type, attributes } = JSON.parse(req.body);
    const notif = getNotification(type, userType);
    const tabName =
      notif.group === notificationGroup.USER
        ? `#${notificationGroup.USER}`
        : `#${notificationGroup.PROJECT}`;

    notification.open({
      id: hash({ type, attributes }),
      duration: 0,
      type: 'success',
      status: 'success',
      className: 'ant-notification-success',
      description: (
        <Router history={history}>
          <Link to={`${notificationsUrl}${tabName}`}>
            {`${formatDate(new Date(), 'DD.MM HH:mm')} : ${notif.pushTitle(attributes)}`}
          </Link>
        </Router>
      )
    });

    store.dispatch(getUnreadNotificationsCountAction());

    if (history.location.pathname === projects) {
      store.dispatch(getFinishedProjects());
      store.dispatch(getOngoingProjects());
      store.dispatch(getPendingProjects());
      store.dispatch(getRejectedProjects());
    }
  });
}

function handleConnectionChange(event) {
  if (event.type === 'offline') {
    pushNotificationsDisconnect();
  }
  if (event.type === 'online') {
    document.location.reload(true);
  }
}

window.addEventListener('online', handleConnectionChange);
window.addEventListener('offline', handleConnectionChange);

const wsMiddleware = store => next => action => {
  switch (action.type) {
    case ActionTypes.CONNECT_WEBSOCKET_NOTIFICATIONS:
      pushNotificationsConnect(store);
      next(action);
      break;
    case ActionTypes.USER_LOGOUT_REQUEST:
    case ActionTypes.DISCONNECT_WEBSOCKET_NOTIFICATIONS:
      pushNotificationsDisconnect();
      next(action);
      break;
    default:
      next(action);
  }
  return store;
};

export default wsMiddleware;
