import { ActionTypes as ActionTypesShared } from 'shared/constants/';
import initialState from './initialState';
import { ActionTypes } from '../constants';

export default (state = initialState.payments, action) => {
  switch (action.type) {
    case ActionTypesShared.GET_WALLET_BALANCE_SUCCESS:
      return {
        ...state,
        wallet: action.payload
      };
    case ActionTypesShared.GET_USER_PLATFORM_COMMISSION_SUCCESS:
      return {
        ...state,
        platformCommission: action.payload
      };
    case ActionTypesShared.GET_PROJECT_PUBLISHING_PRICE_SUCCESS:
      return {
        ...state,
        publishingPrice: action.payload
      };

    case ActionTypesShared.GET_SUBSCRIPTION_PRICE_SUCCESS:
      return {
        ...state,
        subscriptions: action.payload,
      };

    default:
      return state;
  }
};
