import { takeLatest } from 'redux-saga/effects';
import { call, put, select } from 'redux-saga/effects';
import notification from '../utils/notifications';
import { ActionTypes } from '../constants';
import utils from '../utils';
import Api from '../api';
import { formatDate } from '../utils';

function* exportUsersToExel({ payload }) {
  const userType = payload.userType;
  const downloadUserType = userType.toLowerCase();
  const dateNow = formatDate(new Date(), 'DDMMYY');
  const getExelDownloadUrl = `${utils.config.exelUrl}/xlsx?userType=${userType}`;

  yield put({ type: ActionTypes.SHOW_SPINNER });

  const { request, response } = yield call(Api.Exel.getUserFileExel, getExelDownloadUrl);

  if (request && request.status === 200) {
    let a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(response);
    a.download = `${downloadUserType}s_${dateNow}.xlsx`;
    a.click();
  } else {
    yield call(notification, {
      type: 'error',
      data: { description: "File wasn't downloaded. Please try again." }
    });
  }

  yield put({ type: ActionTypes.HIDE_SPINNER });
}

export default function* watchexportUsersToExel() {
  yield takeLatest(ActionTypes.EXPORT_USERS_TO_EXEL, exportUsersToExel);
}
