import * as paths from 'shared/routes/urlLocations';

const hasProjectDetails = link => {
  const regexp = /project-details/gi;
  const projectDetails = regexp.test(link);

  if (projectDetails) {
    const projectId = link.split('/').pop();
    return {
      pathname: link,
      hasProjectId: true,
      projectId: projectId
    };
  }
  return { pathname: link };
};

const defaultReferer = {
  get({ from: { pathname, search } }) {
    if (pathname) {
      return hasProjectDetails(pathname + search);
    }

    return { pathname: paths.dashboard };
  },

  set(target, prop, value) {
    target[prop] = value;
    return true;
  }
};

export default function defaultRedirect(obj = { from: { pathname: null, search: null } }) {
  return new Proxy(obj, defaultReferer);
}
