import ActiveRecord from './ActiveRecord';
import NetworkLayer from '../utils/networkLayer';

export default class extends ActiveRecord {
  getUserFileExel = url => {
    const request = {
      headers: {
        Accept: 'application/xlsx',
        'Content-Type': 'application/xlsx'
      },
      responseType: 'blob'
    };
    return NetworkLayer.getJson(url, request)
      .then(response => response)
      .catch(e => e);
  };
}
