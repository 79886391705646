import {
  takeLatest, takeEvery, call, put, select
} from 'redux-saga/effects';
import Api from '../api';
import patternSaga from './patternSaga';
import { ActionTypes } from 'shared/constants';
import { chartTypes } from 'shared/constants/charts';
import utils from '../utils';
import {
  getChartStart,
  getChartSuccess,
  getChartFailure,
  getChartAction,
  setChartParams
} from 'shared/actions/dashboardActions';

const getDashboardStructureConfig = {
  requestUrl: `${utils.config.apiUserUrl}/charts`,
  requestFunction: Api.Dashboard.getDashboardStructure
};

function* getChartConfig({ requestConfig: { path, chartType, view } }) {
  try {
    const {
      dashboards: { charts }
    } = yield select();
    const { params } = charts[`${chartType}-${view}`];

    yield put(getChartStart(chartType, view));

    const { response, request } = yield call(
      Api.Dashboard.getChart,
      `${utils.config.apiProjectUrl}`,
      {
        path,
        chartType,
        params,
        view
      }
    );

    if (request.status && request.status === 200) {
      yield put(getChartSuccess(chartType, view, response));
    }
  } catch (error) {
    yield put(getChartFailure(chartType, view));
  }
}

function* getSpsAmountConfig({ requestConfig: { path, chartType, view } }) {
  try {
    const {
      dashboards: { charts }
    } = yield select();
    const { params } = charts[`${chartType}-${view}`];
    let url = `${utils.config.apiUserUrl}/${path}`;

    yield put(getChartStart(chartType, view));

    if (chartType === chartTypes.AMOUNT_OF_SP_USERS) {
      url = `${utils.config.apiUserUrl}/${path}?subDomain=${params.sp}`;
    }

    const { response, request } = yield call(Api.Dashboard.getStatistics, url, {
      path,
      chartType,
      params,
      view
    });

    if (request.status && request.status === 200) {
      yield put(getChartSuccess(chartType, view, response));
    }
  } catch (error) {
    yield put(getChartFailure(chartType, view));
  }
}

function* setChartParamsSaga({
  payload: {
    path, chartType, params, view
  }
}) {
  yield put(setChartParams(chartType, view, params));

  yield put(
    getChartAction({
      chartType,
      view,
      path
    })
  );
}

const tagsSagaConfig = {
  requestFunction: Api.Dashboard.getAllTags,
  requestUrl: `${utils.config.apiProjectUrl}/tags`,
  onFailure: {
    notification: true
  }
};

export default function* dashboardWatch() {
  yield takeEvery(ActionTypes.CHART_REQUEST, getChartConfig);
  yield takeEvery(ActionTypes.CHART_REQUEST_USERS, getSpsAmountConfig);
  yield takeEvery(ActionTypes.CHART_PARAMS_CHANGED, setChartParamsSaga);
  yield takeLatest(ActionTypes.GET_ALL_TAGS, patternSaga, tagsSagaConfig);

  yield takeLatest(ActionTypes.GET_DASHBOARD_STRUCTURE, patternSaga, getDashboardStructureConfig);
}
