import hash from 'object-hash';
import notification from 'shared/utils/notification';
import { message as messageAnt } from 'antd';
import messages from '../locales/en/common.json';
import staticMessages from '../locales/en/static.json';

export default ({
  type, data, notificationType = 'notification', staticNotification = false
}) => {
  let description = '';
  let message = '';

  let config = {
    type,
    duration: 0,
    description: '',
    className: `ant-notification-${type}`
  };
  let codes = staticNotification ? staticMessages[type] : messages[type];
  const id = [];
  let typeOf = {
    notification: data => {
      switch (data.constructor.name) {
        case 'Array':
          const prefix = data.length > 1 ? `\u22C5` : '';
          data.forEach(item => {
            let value = item.data && item.data.value ? item.data.value : '';
            let field = item.data && item.data.field ? item.data.field : '';
            message = '';
            if (codes[item.code]) message = codes[item.code].replace('%field', field).replace('%value', value);
            description += message
              ? `${prefix} ${message} \n`
              : item.description
                ? `${prefix} ${item.description}${'\n'}`
                : `${description} ${message} ${'\n'}`;
            id.push({ code: item.code, field, value });
          });
          break;
        case 'Object':
          const value = data.value ? data.value : '';
          const field = data.field ? data.field : '';
          message = '';
          if (codes[data.code]) message = codes[data.code].replace('%field', field).replace('%value', value);
          description += message
            ? `${message} \n`
            : data.description
              ? `${data.description}${'\n'}`
              : `${description} ${message} ${'\n'}`;
          id.push({ code: data.code, field, value });
      }
      config.id = hash(id.sort((a, b) => a.code > b.code));
      config.description = description;
      notification.open(config);
    },
    message: data => {
      switch (data.constructor.name) {
        case 'Object':
          description = data;
          break;
      }
      messageAnt[type](data);
    }
  };

  return typeOf[notificationType](data);
};
