import { ActionTypes as ActionTypesLocal } from '../constants/';
import { ActionTypes as ActionTypesShared } from 'shared/constants/';
import { call, put, select } from 'redux-saga/effects';
import { history } from 'shared/routes/urlLocations';
import notifications from '../utils/notifications';
import { isFunction } from 'shared/utils';

const ActionTypes = { ...ActionTypesLocal, ...ActionTypesShared };

/*
Example of usage:

  const sagaConfig = {
    spinner: true,
    requestFunction: () => {},
    requestUrl: '',
    onSuccess: {
      redirectTo: '' or [pathname, state],
      notification: {
        type: "success",
        notificationType: "notification",
        staticNotification: true // if required
        data: {
          code: "2" // Code of message in locale (Custom)
        },
        field: "email" // field for change
        ...otherNotificationConfigurations
      },
      handler: () => {},
      trigAction: { type: "ACTION_TYPE", payload } || [{ type: "ACTION_TYPE", payload }, ...]
    },
    onFailure: {
      redirectTo: '' or [pathname, state],
      notification: {
        type: "success",
        notificationType: "notification",
        staticNotification: true // if required
        data: {
          code: "2" // Code of message in locale (Custom)
        },
        ...otherNotificationConfigurations
      },
      handler: () => {},
      trigAction: trigAction: { type: "ACTION_TYPE", payload } || [{ type: "ACTION_TYPE", payload }, ...]
    },
  };

  takeEvery('SOME_USERS_GET_REQUEST', patternSaga, sagaConfig);

Action object can looks like:

  const action = {
   type: 'ISSUER_PROJECT_CREATE_SAVE_FILES_LINKS_REQUEST',
   requestConfig: {
    projection: 'published',
    sort: 'sortTitle,asc',
    page: {
      size: 10,
      current: 0
    },
   },
   onSuccessActions: { type: "ACTION_NAME, payload } || [{ type: "ACTION_NAME, payload }, ...],
   onFailureActions: { type: "ACTION_NAME, payload } || [{ type: "ACTION_NAME, payload }, ...],
  };

action.requestParams later will be set ether as request params or request data in network.

---------------------------------------------------------------------
Also you can add to Action Creator fn - "dispatchCallBack" property in action
list or one action (with himself payload)
which will be called on Success or Failer saga
---------------------------------------------------------------------
Example:
// Action Creator
export const projectDataRoomAccessApprove = (projectId, userId, callBackOnSuccess = null, callBackOnFailure = null) => {
  return {
    type: ActionTypes.PROJECT_DATA_ROOM_ACCESS_APPROVE_REQUEST,
    requestConfig: {
      projectId,
      userId,
    },
    dispatchCallBack: {
      onSuccess: callBackOnSuccess,
      onFailed: callBackOnFailure
    }
  };
}

// Container
const mapDispatchToProps = dispatch => ({
  projectDataRoomAccess: (id, user, callBackOnSuccess, callBackOnFailure) =>
   dispatch(projectDataRoomAccess(id, user, callBackOnSuccess, callBackOnFailure)),
});

---------
Call Action Creator in some Component (For Expl: Modal -> button -> onclick)
---------
<Button
  onClick={() => {
    projectDataRoomAccessApprove(
      projectId,
      currentUser.id,
      [callBackOnSuccess, callBackOnSuccess],
      callBackOnFailure
    );
  }}
>Approve</Button>
*/

function* onTrigAction(trigAction, payload) {
  if (trigAction) {
    if (Array.isArray(trigAction)) {
      for (let i = 0; i < trigAction.length; i++) {
        if (isFunction(trigAction[i])) {
          yield put(trigAction[i](payload));
        } else {
          yield put(trigAction[i]);
        }
      }
    } else if (isFunction(trigAction)) {
      yield put(trigAction(payload));
    } else {
      yield put(trigAction);
    }
  }
}

function* onRedirectTo(redirectTo, response, payload = {}){
  if(redirectTo){
    if (isFunction(redirectTo)){
      redirectTo(response, payload);
    } else {
      Array.isArray(redirectTo)
            ? yield history.push(...redirectTo)
            : yield history.push(redirectTo);
    }
  }
}

export default function* patternSaga(sagaConfig = {}, action = {}) {
  const {
    spinner, requestFunction, requestUrl, onSuccess, onFailure
  } = sagaConfig;
  try {
    if (spinner) {
      yield put({ type: ActionTypes.SHOW_SPINNER });
    }

    yield put({ type: ActionTypes[`${action.type}_STARTED`] });

    const preparedRequestUrl = isFunction(requestUrl) ? requestUrl(action) : requestUrl;

    const {
      request: { status },
      response
    } = yield call(requestFunction, preparedRequestUrl, action.requestConfig);

    if (status && (status === 200 || status === 201 || status === 204)) {
      yield put({
        type: ActionTypes[`${action.type}_SUCCESS`],
        payload: response || {}
      });

      if (onSuccess) {
        const {
          redirectTo, notification, trigAction, callback
        } = onSuccess;
        const state = yield select();

        if (redirectTo) {
          yield* onRedirectTo(redirectTo, response, action.requestConfig)
        }
        if (notification) {
          const { ...fields } = action.requestConfig;
          yield call(notifications, {
            ...notification,
            type: 'success',
            data:
              Object.assign({}, notification.data, {
                field: fields[notification.field]
              })
              || response
              || 'Empty message'
          });
        }
        if (callback) {
          callback(state);
        }
        yield* onTrigAction(trigAction, action.requestConfig);
      }

      if (action.dispatchCallBack) {
        if (action.dispatchCallBack.onSuccess) {
          if (Array.isArray(action.dispatchCallBack.onSuccess)) {
            for (let i = 0; i < action.dispatchCallBack.onSuccess.length; i++) {
              action.dispatchCallBack.onSuccess[i]();
            }
          } else {
            action.dispatchCallBack.onSuccess();
          }
        }
      }

      if (action.onSuccessActions) {
        if (Array.isArray(action.onSuccessActions)) {
          for (let i = 0; i < action.onSuccessActions.length; i++) {
            yield put(action.onSuccessActions[i]);
          }
        } else {
          yield put(action.onSuccessActions);
        }
      }
    } else if (status && status === 401) {
      yield put({ type: ActionTypes[`${action.type}_FAILURE`] });

      if (onFailure) {
        const { redirectTo, notification, trigAction } = onFailure;

        if (redirectTo) {
          Array.isArray(redirectTo)
            ? yield history.push(...redirectTo)
            : yield history.push(redirectTo);
        }

        if (notification) {
          yield call(notifications, {
            ...notification,
            type: 'error',
            data: response || notification.data || 'Empty message'
          });
        }

        yield* onTrigAction(trigAction, action.requestConfig);
      }

      if (action.dispatchCallBack) {
        if (action.dispatchCallBack.onFailure) {
          if (Array.isArray(action.dispatchCallBack.onFailure)) {
            for (let i = 0; i < action.dispatchCallBack.onFailure.length; i++) {
              action.dispatchCallBack.onFailure[i]();
            }
          } else {
            action.dispatchCallBack.onFailure();
          }
        }
      }

      yield put({ type: ActionTypes.USER_LOGOUT_REQUEST });
    } else {
      yield put({ type: ActionTypes[`${action.type}_FAILURE`] });

      if (onFailure) {
        const { redirectTo, notification, trigAction } = onFailure;

        if (redirectTo) {
          Array.isArray(redirectTo)
            ? yield history.push(...redirectTo)
            : yield history.push(redirectTo);
        }

        if (notification) {
          yield call(notifications, {
            ...notification,
            type: 'error',
            data: response || notification.data || 'Empty message'
          });
        }

        yield* onTrigAction(trigAction, action.requestConfig);
      }

      if (action.dispatchCallBack) {
        if (action.dispatchCallBack.onFailure) {
          if (Array.isArray(action.dispatchCallBack.onFailure)) {
            for (let i = 0; i < action.dispatchCallBack.onFailure.length; i++) {
              action.dispatchCallBack.onFailure[i]();
            }
          } else {
            action.dispatchCallBack.onFailure();
          }
        }
      }

      if (action.onFailureActions) {
        if (Array.isArray(action.onFailureActions)) {
          for (let i = 0; i < action.onFailureActions.length; i++) {
            yield put(action.onFailureActions[i]);
          }
        } else {
          yield put(action.onFailureActions);
        }
      }
    }
  } catch ({ message }) {
    console.error(message);
    yield put({ type: ActionTypes[`${action.type}_FAILURE`] });
  } finally {
    yield put({ type: ActionTypes[`${action.type}_FINISHED`] });

    if (spinner) {
      yield put({ type: ActionTypes.HIDE_SPINNER });
    }
  }
}
