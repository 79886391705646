import React from 'react';
import { takeLatest } from 'redux-saga/effects';
import Api from '../api';
import patternSaga from './patternSaga';
import { ActionTypes } from '../constants';
import utils from '../utils';

const getUnreadNotificationsCountConfig = {
  requestFunction: Api.Notifications.getUnreadNotifications,
  requestUrl: `${utils.config.apiNotificationsUrl}/count`,
  onFailure: {
    notification: true
  }
};

export default function* notificationsWatch() {
  yield takeLatest(
    ActionTypes.GET_UNREAD_NOTIFICATIONS_COUNT,
    patternSaga,
    getUnreadNotificationsCountConfig
  );
}
