import ActiveRecord from './ActiveRecord';
import Auth from './auth';
import Registration from './registration';
import User from './user';
import Projects from './projects';
import Notifications from './notifications';
import Dashboard from './dashboard';
import networkLayer from '../utils/networkLayer';
import Exel from './exel';

class Api extends ActiveRecord {
  constructor() {
    super();
    this._auth = new Auth();
    this._registration = new Registration();
    this._user = new User();
    this._projects = new Projects();
    this._notifications = new Notifications();
    this._dashboard = new Dashboard();
    this._exel = new Exel();
    networkLayer.authToken = sessionStorage.getItem('isAuthenticated')
      && `${sessionStorage.token_type} ${sessionStorage.access_token}`;
  }

  get Auth() {
    return this._auth;
  }

  get Registration() {
    return this._registration;
  }

  get User() {
    return this._user;
  }

  get Projects() {
    return this._projects;
  }

  get Notifications() {
    return this._notifications;
  }

  get Dashboard() {
    return this._dashboard;
  }

  get Exel() {
    return this._exel;
  }

  set store(store) {
    this._store = store;

    // this._store.subscribe(() => this._updateToken())
    // this._updateToken()
  }

  get store() {
    return this._store;
  }

  get dispatch() {
    return this._store.dispatch;
  }

  // _updateToken() {
  //   let { auth: { token } } = this._store.getState()
  //   this._networkLayer.authToken = token
  // }
}

export default new Api();
