import { ActionTypes } from "../constants";
import initialState from "./initialState";

export default (state = initialState.usersLists, action) => {
  switch (action.type) {
    case ActionTypes.ADMIN_USERS_LIST_REQUEST_SUCCESS:
      return { ...state, adminUsers: { list: action.payload } };

    case ActionTypes.STRATEGIC_PARTNERS_LIST_REQUEST_SUCCESS:
      return { ...state, strategicPartners: { list: action.payload } };

    case ActionTypes.ISSUERS_LIST_REQUEST_SUCCESS:
      return { ...state, issuers: { list: action.payload } };

    case ActionTypes.INVESTORS_LIST_REQUEST_SUCCESS:
      return { ...state, investors: { list: action.payload } };

    case ActionTypes.ADVISORS_LIST_REQUEST_SUCCESS:
      return { ...state, advisors: { list: action.payload } };

    case ActionTypes.SP_INVESTORS_LIST_FETCH_SUCCESS:
      return { ...state, SPInvestors: { list: action.payload } };

    case ActionTypes.SP_ISSUERS_LIST_FETCH_SUCCESS:
      return { ...state, SPIssuers: { list: action.payload } };

    default:
      return state;
  }
};
