import { takeEvery } from "redux-saga";
import { ActionTypes } from "shared/constants/";
import utils from "../utils";
import patternSaga from "./patternSaga";
import Api from "../api";
// import notification from "../utils/notifications";


// const moneyRepayConfig = {
//   spinner: true,
//   requestFunction: Api.User.moneyRepay,
//   requestUrl: `${utils.config.apiPaymentsUrl}/payouts/bankwire`,
//   onSuccess: {
//     notification: {
//       notificationType: "notification",
//       staticNotification: true,
//       data: {
//         code: "8"
//       }
//     }
//   },
//   onFailure: {
//     notification: true,
//     notification: {
//       notificationType: "notification",
//       staticNotification: true,
//       data: {
//         code: "5"
//       }
//     }
//   }
// };


const getWalletBalanceConfig = {
  spinner: true,
  requestFunction: Api.User.getWalletBalance,
  requestConfig: {},
  requestUrl: `${utils.config.apiPaymentsUrl}/client/wallet/balance`,
  onFailure: {
    notification: true
  }
};

export function* getWalletStatus() {
  yield takeEvery(ActionTypes.GET_WALLET_BALANCE, patternSaga, getWalletBalanceConfig);
  // yield takeEvery(ActionTypes.REPAY_MONEY, patternSaga, moneyRepayConfig);
}
