import isEmpty from 'lodash/isEmpty';
import ActiveRecord from './ActiveRecord';
import NetworkLayer from '../utils/networkLayer';

class Dashboard extends ActiveRecord {
  constructor() {
    super();
    this._logPromise = Promise.resolve();
  }

  getStatistics(url) {
    const request = {
      data: {}
    };

    return NetworkLayer.getJson(url, request)
      .then(response => response)
      .catch(e => e);
  }

  getChart(
    url,
    { path, params: { size, startDate, endDate, timePoint, regions, productCategories } }
  ) {
    const tags = [];

    if (!isEmpty(productCategories)) {
      productCategories.forEach(productCategory => {
        tags.push({
          id: productCategory,
          type: 'PRODUCT_CATEGORY',
          tags: []
        });
      });
    }

    if (!isEmpty(regions)) {
      regions.forEach(region => {
        tags.push({
          id: region,
          type: 'REGION',
          tags: []
        });
      });
    }

    const request = {
      params: {
        size
      },
      data: {
        timePoint,
        startDate,
        endDate,
        tags
      }
    };
    return NetworkLayer.postJson(`${url}/${path}`, request)
      .then(response => response)
      .catch(e => e);
  }

  getDashboardStructure = url => {
    return NetworkLayer.getJson(url)
      .then(response => response)
      .catch(e => e);
  };

  getAllTags = url => {
    return NetworkLayer.getJson(url)
      .then(response => response)
      .catch(e => e);
  };
}

export default Dashboard;
