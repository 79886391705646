import { takeEvery, call, put } from 'redux-saga/effects';
import { ActionTypes } from 'shared/constants';

import Api from '../api';

function* getSpPlatformConfig({ payload }) {
  try {
    const { response, request } = yield call(Api.User.getSpPlatformConfig, payload.subDomain);

    if (request.status && request.status === 200) {
      yield put({
        type: ActionTypes.GET_SP_PLATFORM_CONFIG_SUCCESS,
        payload: response
      });
    }
  } catch (e) {
    console.log('error: ', e);
  }
}

export default function* watchSpPlatformSaga() {
  yield takeEvery(ActionTypes.GET_SP_PLATFORM_CONFIG, getSpPlatformConfig);
}
