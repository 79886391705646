import { takeEvery, call, put } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import utils from "../utils";
import Api from "../api";
import notification from "../utils/notifications";
import { history } from 'shared/routes/urlLocations';
import setUnloginedUserType from "../utils/setUnloginedUserType";

function* restorePassword({ token, values: { password, confirmPassword } }) {
  yield put({ type: ActionTypes.RESTORE_PASSWORD_REQUEST_STARTED });
  yield put({ type: ActionTypes.SHOW_SPINNER });

  try {
    const { response, request, status } = yield call(
      Api.Registration.confirmationEmail,
      `${utils.config.apiUserUrl}/public/password/restore/${token}`,
      {
        password,
        passwordConfirmation: confirmPassword
      }
    );

    if (request.status && request.status === 200) {
      yield call(notification, {
        type: "success",
        data: { code: 2 },
        staticNotification: true
      });
      yield call(setUnloginedUserType, response.userType);
      yield history.push("/login");
    } else {
      yield call(notification, {
        type: "error",
        data: response
      });
    }
  } catch ({ message }) {
    console.error(message);
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
    yield put({ type: ActionTypes.RESTORE_PASSWORD_REQUEST_FINISHED });
  }
}

function* checkUsedToken({ token }) {
  try {
    yield put({ type: ActionTypes.CHECK_USED_TOKEN_REQUEST_STARTED });

    const { response, request, status } = yield call(
      Api.Auth.checkUsedToken,
      `${utils.config.apiUserUrl}/public/password/${token}`
    );

    if (request.status && request.status !== 200) {
      yield call(notification, {
        type: "error",
        data: response
      });
      if (!sessionStorage.getItem("userType")) {
        yield history.push("/restore-password");
      } else {
        yield history.push("/login");
      }
    }
  } catch ({ message }) {
    console.error(message);
  } finally {
    yield put({ type: ActionTypes.CHECK_USED_TOKEN_REQUEST_FINISHED });
  }
}

export function* watchRestorePassword() {
  yield takeEvery(ActionTypes.RESTORE_PASSWORD_REQUEST, restorePassword);
}

export function* watchCheckUsedToken() {
  yield takeEvery(ActionTypes.CHECK_USED_TOKEN_REQUEST, checkUsedToken);
}
